<template>
    <div>
        <div class="header">
            <ds-header title="查看活动" style="width: 100%"></ds-header>
            <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
        </div>
        <a-steps :current="current">
            <a-step>
                <template slot="title">
                    步骤一
                </template>
                <span slot="description">活动信息</span>
            </a-step>
            <a-step title="步骤二" description="活动设置" />
            <a-step title="步骤三" description="问卷设置" />
        </a-steps>
        <div class="box" v-show="current == 0">
            <div class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>活动标题：</span></p><span>{{
                    formItem.activityTitle
                }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>活动封面：</span></p>

                <img alt="example" style="width:100px" @click="previewVisible = true" :src="formItem.activityImage" />
                <a-modal style="z-index: 1000000;" :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%;margin-top: 20px;" :src="formItem.activityImage" />
                </a-modal>
            </div>
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动类型：</span></p> <span>{{
                    formItem.activityTypeName }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动周期：</span></p> <span>{{
                    formItem.activityPeriod == 0 ? '单次活动' : '周期性活动' }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动时间：</span></p>
                <div style="flex: 1;">
                    <a-table :rowKey="(record, index) => index" :columns="columns"
                        :data-source="formItem.activityPeriods" :pagination="false" bordered>
                    </a-table>
                </div>
            </div>
            <!-- <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动时间：</span></p> <span>{{ formItem.startTime
                }}~{{ formItem.endTime }}</span>
            </div> -->
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动地址：</span></p> <span>{{ formItem.address
                    }}</span>
            </div>
            <div v-if="!formItem.viewDetailAddress" class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>活动详细地址：</span></p> <span>{{
                    formItem.detailAddress }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>活动详情：</span></p>
                <div class="content" style="z-index: 0;">
                    <div id="editer" style="z-index: 0;"></div>
                </div>
            </div>
        </div>
        <div class="box" v-show="current == 1">
            <div class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>适用项目：</span></p><span>{{
                    formItem.projectStr
                }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>是否可以分享：</span></p> <span>{{
                    formItem.isShare ? '否' : "是" }}</span>
            </div>
            <div class="title_tiem">
                <p class="title_width"><span class="required">*</span><span>是否需要报名：</span></p> <span>{{
                    formItem.isApply ? '否' : '是'
                }}</span>
            </div>
            <div class="title_tiem" v-if="formItem.isApply">
                <p class="title_width"><span class="required">*</span><span>是否展示无需报名：</span></p> <span>{{
                    formItem.isShowNeedNotRegister ? '是' : '否'
                }}</span>
            </div>
            <template v-if="!formItem.isApply">
                <div class="title_tiem">
                    <p class="title_width"> <span class="required">*</span><span>活动用户限制：</span></p>
                    <span>{{ userRestriction(formItem.userRestriction) }}</span>
                </div>
                <div v-if="formItem.userRestriction === 2" class="title_tiem">
                    <p class="title_width"><span class="required">*</span><span>适用企业类型：</span></p> <span>{{
                        formItem.enterpriseTypeStr ? formItem.enterpriseTypeStr : '-' }}</span>
                </div>
                <div v-if="formItem.userRestriction === 3" class="title_tiem">
                    <p class="title_width"><span class="required">*</span><span>指定用户名单：</span></p> <span
                        style="color: #1890ff;cursor: pointer;" @click="GET_USER_LIST"> {{ formItem.fileName }}</span>
                </div>
                <div v-if="formItem.userRestriction != 3 && formItem.personnelCeilingType" class="title_tiem">
                    <p class="title_width"><span class="required">*</span><span>报名人数限制：</span></p>
                    <span>{{ formItem.personnelCeilingType == 2 ? "无限制" : formItem.personnelCeiling }}</span>
                </div>
                <div class="title_tiem">
                    <p class="title_width"> <span class="required">*</span><span>选择报名信息模板：</span></p> <span>{{
                        formItem.applyTemplate === 1 ? '模板1' : formItem.applyTemplate === 2 ?
                            '模板二' : formItem.applyTemplate === 3 ? '模板三' : '模板四' }}</span>
                </div>
                <div class="title_tiem " style="margin-top:0">
                    <p class="title_width"> <span class="required"></span><span></span></p> <span></span>
                    <p v-if="formItem.applyTemplate === 1" style="color: #ccc;">报名信息：联系人姓名，联系人电话，企业名称，入住项目，联系人职位（可选）</p>
                    <p v-else-if="formItem.applyTemplate === 2" style="color: #ccc;">
                        联系人姓名、联系人电话、企业名称、入驻项目、联系人职位（可选）、企业遇到的问题（可选）</p>
                    <p v-else-if="formItem.applyTemplate === 3" style="color: #ccc;">联系人姓名、联系人电话</p>
                    <p v-else style="color: #ccc;">联系人姓名、联系人电话、企业/社区名称、服务意向、备注（可选）</p>
                </div>
                <div class="title_tiem">
                    <p class="title_width"> <span class="required">*</span><span>活动参与方式：</span></p> <span>{{
                        formItem.participationWay==0 ? '线下签到' : formItem.participationWay==1 ? '无需签到':'线上签到' }}</span>
                </div>
                <div class="title_tiem">
                    <p class="title_width"> <span class="required">*</span><span>是否消息通知：</span></p> <span>{{
                        formItem.isNotification ? '否' : "是" }}</span>
                </div>
                <template v-if="!formItem.isNotification">
                    <div class="title_tiem">
                        <p class="title_width"> <span class="required">*</span><span>消息通知方式：</span></p> <span>{{
                            formItem.notificationType }}</span>
                    </div>
                    <div class="title_tiem">
                        <p class="title_width"> <span class="required">*</span><span>消息推送时间：</span></p> <span>{{
                            formItem.messagePushTime }}</span>
                    </div>
                </template>
                <div class="title_tiem">
                    <p class="title_width"> <span class="required">*</span><span>报名后活动详细地址：</span></p> <span>{{
                        formItem.viewDetailAddress ? '关闭' : '开启' }}</span>
                </div>


            </template>

        </div>
        <div class="box" v-show="current == 2">
            <div class="title_tiem">
                <p class="title_width"> <span class="required">*</span><span>是否发放问卷：</span></p><span>{{
                    formItem.isQuestionnaire ? '否' : '是'
                }}</span>
            </div>
            <div class="title_tiem" v-if="!formItem.isQuestionnaire">
                <p class="title_width"> <span class="required">*</span><span>问卷选择：</span></p><span>{{
                    formItem.questionnaireName
                }}</span>
            </div>
        </div>
        <div class="btn_box">
            <div>
                <a-button v-if="current == 0" style="margin-right:20px" type="primary"
                    @click="() => this.$router.go(-1)">返回</a-button>
                <a-button v-else style="margin-right:20px" type="primary" @click="() => current += -1">上一步</a-button>
                <a-button v-if="current !== 2" type="dashed" @click="() => current += 1">下一步</a-button>
                <a-button v-if="current == 2" style="margin-right:20px" type="dashed"
                    @click="() => this.$router.go(-1)">完成</a-button>
            </div>

        </div>
    </div>
</template>
<script>
import { IMG_API } from "@/config";
import { GET_LIST_DETAIL, GET_USER_LIST } from "../../../api/enterpriseServiceActivitiesManagement"
import wangEditor from "wangeditor";
import moment from 'moment';
export default {
    data() {
        return {
            current: 0,//步骤条
            formItem: {},
            moment,
            previewVisible: false,
            IMG_API: IMG_API,
            columns:[
                    {
                        title: "活动时间",
                        key: "startTime",
                        customRender(text, row) {
                            return moment(row.startTime).format('YYYY-MM-DD HH:mm') + ' - ' + moment(row.endTime).format('YYYY-MM-DD HH:mm')
                        },
                    },
                    {
                        title: "活动生效时间",
                        dataIndex: "publishType",
                        key: "publishType",
                        customRender(text, row) {
                            return row.publishType==0?'立即发布':'提前'+row.publishType+'天发布'
                        },
                    },
                    {
                        title: "活动场地",
                        dataIndex: "siteName",
                        key: "siteName",
                        customRender(text, row) {
                           return row.siteName?row.siteName:'-'
                        },
                    },
                    {
                        title: "维护时间及场次",
                        dataIndex: "maintainTime",
                        key: "maintainTime",
                        customRender(text, row) {
                           return row.maintainTime?row.maintainTime:'-'
                        },
                    },
                    {
                        title: "备注",
                        dataIndex: "notice",
                        key: "notice",
                        ellipsis:{showTitle: false },
                        tooltip: { title: ({ value }) => value },
                        customRender(text, row) {
                           return row.notice?row.notice:'-'
                        },
                    },
            ]
        }
    },
    created() {
        this.GET_LIST_DETAIL({ activityId: this.$route.query.id })
    },
    mounted() {
        const _this = this;
        const editor = new wangEditor(`#editer`);
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
            this.formItem.consult_content = newHtml;
        };
        editor.config.menus = [
            "fontName",
            "fontSize",
            "foreColor",
            "backColor",
            "underline",
            "italic",
            "bold",
            "justify",
            "splitLine",
            "undo",
            "redo",
            "list",
            "table",
            "image",
            // "video",
        ];
        editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgShowBase64 = false;
        editor.config.showLinkImg = false;
        editor.config.showLinkVideo = false;
        editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
        editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
        // editor.config.uploadVideoAccept = ['mp4']
        editor.config.uploadFileName = "file";
        editor.config.uploadVideoName = "file";
        editor.config.uploadVideoTimeout = 60000;
        editor.config.uploadImgTimeout = 60000;


        editor.config.customAlert = function (s, t) {
            switch (t) {
                case "success":
                    _this.$message.success(s);
                    break;
                case "info":
                    _this.$message.info(s);
                    break;
                case "warning":
                    _this.$message.warning(s);
                    break;
                case "error":
                    _this.$message.error(s);
                    break;
                default:
                    _this.$message.info(s);
                    break;
            }
        };
        editor.config.placeholder = "请输入";
        // 创建编辑器
        editor.create();
        this.editor = editor;
        // 查看的时候禁用富文本
        // if (this.$route.query.type === "info") {
        this.editor.disable();
        // } else {
        // this.editor.enable();
        // }
    },
    methods: {
        handleCancel() {
            this.previewVisible = false;
        },
        async GET_LIST_DETAIL() {
            let data = await GET_LIST_DETAIL({ activityId: this.$route.query.id })
            if (data.code === '200') {
                this.formItem = data.data
                if (this.formItem.isNotification !== 1) {
                    let notificationType = this.formItem.notificationType?.split(',')
                    let messagePushTime = this.formItem.messagePushTime?.split(',')
                    let notificationTypestr = ''
                    let messagePushTimestr = ''
                    for (let i = 0; i < notificationType.length; i++) {
                        const element = notificationType[i];
                        if (element === '0') {
                            notificationTypestr = '短信通知'
                        } else {
                            notificationTypestr += '，app推送'
                        }
                        this.formItem.notificationType = notificationTypestr
                    }
                    for (let i = 0; i < messagePushTime.length; i++) {
                        const element = messagePushTime[i];
                        if (element === '5') {

                            if (messagePushTime.length > 2) {
                                messagePushTimestr = '活动开始前 5分钟，'
                            } else {

                                messagePushTimestr = '活动开始前 5分钟'
                            }
                        } else if (element === '2') {
                            messagePushTimestr += '活动开始前 2小时'

                        } else if (element === '12') {
                            messagePushTimestr += '，活动开始前 12小时'

                        } else if (element === '24') {
                            messagePushTimestr += '，活动开始前 24小时'
                        }
                        this.formItem.messagePushTime = messagePushTimestr
                    }
                }

                this.editor.txt.html(this.formItem.activityDetail);
            }
        },

        userRestriction(val) {
            let str = ''
            switch (val) {
                case 0:
                    str = '不限制'
                    break;
                case 1:
                    str = '项目所有用户'
                    break;
                case 2:
                    str = '项目下指定企业类型用户'
                    break;
                case 3:
                    str = '指定用户名单'
                    break;
                case 4:
                    str = '实名认证用户'
                    break;

                default:
                    break;
            }
            return str
        },

        async GET_USER_LIST() {
            let res = await GET_USER_LIST({ activityId: this.$route.query.id })
            this.downloadCommon(res)
        },
        downloadCommon(res) {
            if ("msSaveOrOpenBlob" in navigator) {
                const url = res.data;
                const blob = new Blob([url], { type: "application/vnd.ms-excel" });
                let name = res.headers["content-disposition"].split("=")[1];
                window.navigator.msSaveOrOpenBlob(blob, decodeURI(name));
                return;
            } else {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                let name = res.headers["content-disposition"].split("=")[1];
                link.setAttribute("download", decodeURI(name));
                document.body.appendChild(link);
                link.click();
            }

        },
    }



}
</script>
<style scoped lang="scss">
.header {
    display: flex;
    justify-content: space-between;
}

.box {
    width: 70%;
    margin: 0 auto;
}

.title_tiem {
    display: flex;
    margin-top: 20px;

    .title_width {
        width: 150px;
        text-align: right;
    }

    .content {
        width: 600px;
    }
}

.btn_box {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.required {
    color: red;
}
</style>